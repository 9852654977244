import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  H3Blue,
  standardWrapper,
  Btn1LightBlue,
  colors,
  Btn1Gold,
} from "../../styles/helpers"

import SimpleWysiwyg from "../PageComponents/SimpleWysiwyg"

const ProgramsList = ({ data }) => {
  return (
    <ProgramsListStyled>
      <div className="wrapper">
        {data.edges.map(program => {
          return (
            <ListItem key={program.node.id}>
              <div className="mainTitle">
                <h2>{program.node.title}</h2>
              </div>
              <div className="mainContent">
                <SimpleWysiwyg
                  data={{ wysiwygContent: program.node.acfProgram.mainContent }}
                />
              </div>
              {program.node.acfProgram.buttonRequired && (
                <div className="mainLink">
                  <a href={program.node.acfProgram.buttonUrl}>
                    {program.node.acfProgram.buttonText}
                  </a>
                </div>
              )}
            </ListItem>
          )
        })}
        <div className="linksNav">
          <Link to="/programs">back to all sports</Link>
          <button disabled>Load More</button>
        </div>
      </div>
    </ProgramsListStyled>
  )
}

const ProgramsListStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .linksNav {
    width: 100%;

    a,
    button {
      ${Btn1Gold};
      margin-right: 2em;
      margin-bottom: 2.5rem;
    }
  }
`

const ListItem = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: solid 0.3rem ${colors.colorSecondary};

  .mainTitle {
    h2 {
      ${H3Blue};
    }
  }

  .mainContent {
    width: 100%;
    .wysiwygContent {
      max-width: 100%;
    }
  }

  .mainLink {
    width: 100%;

    a {
      ${Btn1LightBlue};
    }
  }
`

export default ProgramsList

import React from "react"
import styled from "styled-components"
import { H1Blue, H2Blue, standardWrapper } from "../../styles/helpers"

const MainTitle = ({ data }) => {
  return (
    <MainTitleStyled>
      <div className="wrapper">
        <div className="title">
          <h1>{data.name}</h1>
        </div>
        <div className="description">
          <p>{data.description}</p>
        </div>
      </div>
    </MainTitleStyled>
  )
}

const MainTitleStyled = styled.section`
  background-color: rgba(98, 167, 196, 0.3);
  .wrapper {
    ${standardWrapper};
    max-width: 95rem;
  }

  .title {
    width: 100%;
    text-align: center;

    h1 {
      ${H1Blue};
    }
  }

  .description {
    width: 100%;
    text-align: center;

    p {
      ${H2Blue};
    }
  }
`

export default MainTitle

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

import MainTitle from "../components/ProgramParts/MainTitle"
import ProgramsList from "../components/ProgramParts/ProgramsList"
import BannerCallout from "../components/ProgramParts/BannerCallout"

const Program = props => {
  const { proCat, programs } = props.data
  return (
    <Layout>
      <Seo title="program" />
      <MainTitle data={proCat} />
      <ProgramsList data={programs} />
      <BannerCallout />
    </Layout>
  )
}

export const programTempQuery = graphql`
  query programTempPage($id: String!, $slug: String!) {
    proCat: wpProgramCategory(id: { eq: $id }) {
      name
      description
    }

    programs: allWpProgram(
      filter: {
        programCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
      }
    ) {
      edges {
        node {
          title
          id
          acfProgram {
            mainContent
            buttonUrl
            buttonText
            buttonRequired
          }
        }
      }
    }
  }
`

export default Program
